<template>
  <Section class="section-filters">
    <div class="content tl-11 tl-o-1">
      <slot />
    </div>
  </Section>
</template>
<style scoped lang="scss">
.section-filters {
  position: relative;
  --margin: 42px;
  @include breakpoint(tl) {
    --margin: 60px;
  }
  @include breakpoint(ds) {
    --margin: 80px;
  }

  + :deep(.section) {
    margin-top: 0;
  }
}

.content {
  > :deep(*:not(:last-child)) {
    margin-bottom: 20px;
  }
}
</style>
